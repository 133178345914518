import { createRouter, createWebHashHistory } from 'vue-router';
// console.log(this.menu);
const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./pages/Dashboards/Dashboard.vue')
    },
    {
        path: '/shift-role',
        name: 'shift-role',
        component: () => import('./pages/ShiftRole.vue')
    },
    {
        path: '/mobile-view',
        name: 'mobile-view',
        component: () => import('./pages/MobileView.vue')
    },

    {
        name: 'dashboardRoute',
        path: '/',
        component: () => import('./pages/Dashboards/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Dashboard' }],
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('./pages/Logout.vue'),
        meta: {
            breadcrumb: [{ parent: 'Access Management', label: 'Logout' }],
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('./pages/Register.vue')
    },

    {
        name: 'settings',
        path: '/settings',
        component: () => import('./pages/Settings.vue'),
        meta: {
            breadcrumb: [{ parent: 'Profile Settings', label: 'Settings' }],
        }
    },
    {
        name: 'AccessRoles',
        path: '/access/roles',
        component: () => import('./pages/AccessManagement/Roles.vue'),
        meta: {
            breadcrumb: [{ parent: 'Access Management', label: 'Roles' }],
        }
    },
    {
        name: 'AccessModules',
        path: '/access/modules',
        component: () => import('./pages/AccessManagement/Modules.vue'),
        meta: {
            breadcrumb: [{ parent: 'Access Management', label: 'Modules' }],
        }
    },
    {
        name: 'AccessModulePermissions',
        path: '/access/module-permissions',
        component: () => import('./pages/AccessManagement/ModulePermissions.vue'),
        meta: {
            breadcrumb: [{ parent: 'Access Management', label: 'Module Permissions' }],
        }
    },
    {
        name: 'AccessLevels',
        path: '/access/levels',
        component: () => import('./pages/AccessManagement/AccessLevels.vue'),
        meta: {
            breadcrumb: [{ parent: 'Access Management', label: 'Access Levels' }],
        }
    },

    {
        name: 'Users',
        path: '/access/manage-users',
        component: () => import('./pages/AccessManagement/ManageUsers.vue'),
        meta: {
            breadcrumb: [{ parent: 'Access Management', label: 'Users' }],
        }
    },

    {
        path: '/access/manage-users-categories',
        name: 'User Categories',
        component: () => import('./pages/AccessManagement/UserCategories.vue'),
        meta: {
            breadcrumb: [{ parent: 'Access Management', label: 'User Categories' }],
        }
    },

    {
        name: 'CompanyProfile',
        path: '/companies/manage-company-profile',
        component: () => import('./pages/Companies/ManageCompanyProfile.vue'),
        meta: {
            breadcrumb: [{ parent: 'Company Management', label: 'Company Profile' }],
        }
    },
    {
        name: 'Companies',
        path: '/companies/manage-companies',
        component: () => import('./pages/Companies/ManageCompanies.vue'),
        meta: {
            breadcrumb: [{ parent: 'Company Management', label: 'Companies' }],
        }
    },
    {
        name: 'Branches',
        path: '/companies/manage-branches',
        component: () => import('./pages/Companies/ManageBranches.vue'),
        meta: {
            breadcrumb: [{ parent: 'Company Management', label: 'Branches' }],
        }
    },
    {
        name: 'Subsidiaries',
        path: '/companies/manage-subsidiaries',
        component: () => import('./pages/Companies/ManageSubsidiaries.vue'),
        meta: {
            breadcrumb: [{ parent: 'Company Management', label: 'Subsidiaries' }],
        }
    },
    {
        name: 'Departments',
        path: '/companies/manage-departments',
        component: () => import('./pages/Companies/ManageDepartments.vue'),
        meta: {
            breadcrumb: [{ parent: 'Company Management', label: 'Departments' }],
        }
    },
    {
        name: 'Designations',
        path: '/companies/manage-designations',
        component: () => import('./pages/Companies/ManageDesignations.vue'),
        meta: {
            breadcrumb: [{ parent: 'Company Management', label: 'Designations' }],
        }
    },
    {
        name: 'CanceledReasons',
        path: '/companies/manage-canceled-reasons',
        component: () => import('./pages/Companies/ManageCanceledReasons.vue'),
        meta: {
            breadcrumb: [{ parent: 'Company Management', label: 'Canceled Reasons' }],
        }
    },
    {
        name: 'JourneyTypes',
        path: '/companies/manage-journey-types',
        component: () => import('./pages/Companies/ManageJourneyTypes.vue'),
        meta: {
            breadcrumb: [{ parent: 'Company', pparent: 'Management', label: 'Journey Types' }],
        }
    },
    {
        name: 'VehicleTypeGroups',
        path: '/vehicles/manage-vehicle-type-groups',
        component: () => import('./pages/Vehicles/ManageVehicleTypeGroups.vue'),
        meta: {
            breadcrumb: [{ parent: 'Vehicles Management', label: 'Type Groups' }],
        }
    },
    {
        name: 'VehicleTaxRates',
        path: '/vehicles/manage-vehicle-tax-rates',
        component: () => import('./pages/Vehicles/ManageVehicleTaxRates.vue'),
        meta: {
            breadcrumb: [{ parent: 'Vehicles Management', label: 'Tax Rates' }],
        }
    },
    {
        name: 'VehicleCargoGroups',
        path: '/vehicles/manage-vehicle-cargo-groups',
        component: () => import('./pages/Vehicles/ManageVehicleCargoGroups.vue'),
        meta: {
            breadcrumb: [{ parent: 'Vehicles Management', label: 'Cargo Groups' }],
        }
    },
    {
        name: 'VehicleCargoManifests',
        path: '/vehicles/manage-vehicle-cargo-manifests',
        component: () => import('./pages/Vehicles/ManageVehicleCargoManifests.vue'),
        meta: {
            breadcrumb: [{ parent: 'Vehicles Management', label: 'Cargo Manifests' }],
        }
    },
    {
        name: 'VehicleCargoTypes',
        path: '/vehicles/manage-vehicle-cargo-types',
        component: () => import('./pages/Vehicles/ManageVehicleCargoTypes.vue'),
        meta: {
            breadcrumb: [{ parent: 'Vehicles Management', label: 'Cargo Types' }],
        }
    },
    {
        name: 'VehicleTypes',
        path: '/vehicles/manage-vehicle-types',
        component: () => import('./pages/Vehicles/ManageVehicleTypes.vue'),
        meta: {
            breadcrumb: [{ parent: 'Vehicles Management', label: 'Vehicle Types' }],
        }
    },
    {
        name: 'Vehicles',
        path: '/vehicles/manage-vehicles',
        component: () => import('./pages/Vehicles/ManageVehicles.vue'),
        meta: {
            breadcrumb: [{ parent: 'Vehicles Management', label: 'Vehicles' }],
        }
    },
    {
        name: 'Products',
        path: '/products/manage-products',
        component: () => import('./pages/Products/Products.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Products', label: 'Products' }],
        }
    },
    {
        name: 'ProductCategories',
        path: '/products/manage-product-categories',
        component: () => import('./pages/Products/ProductCategories.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Products', label: 'Product Categories' }],
        }
    },
    {
        name: 'Customers',
        path: '/customers/manage-customers',
        component: () => import('./pages/Customers/Customers.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Customers', label: 'Manage Customer' }],
        }
    },
    {
        name: 'CustomerCategories',
        path: '/customers/manage-customer-categories',
        component: () => import('./pages/Customers/CustomerCategories.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Customer', label: 'Customers' }],
        }
    },
    {
        name: 'SupplierCategories',
        path: '/Suppliers/manage-supplier-categories',
        component: () => import('./pages/Suppliers/SupplierCategories.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Supplier', label: 'Supplier Categories' }],
        }
    },
    {
        name: 'SupplierDocumentTypes',
        path: '/Suppliers/manage-supplier-document-types',
        component: () => import('./pages/Suppliers/SupplierDocumentTypes.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Supplier', label: 'Supplier Document Types' }],
        }
    },
    {
        name: 'Suppliers',
        path: '/Suppliers/manage-suppliers',
        component: () => import('./pages/Suppliers/Suppliers.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Supplier', label: 'Suppliers' }],
        }
    },
    {
        name: 'Orders',
        path: '/Orders/manage-orders',
        component: () => import('./pages/Orders/Orders.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Orders', label: 'Orders' }],
        }
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./pages/Calendar.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Calendar', label: 'Calendar' }],
        }
    },
    {
        path: '/automation-settings',
        name: 'automationSettings',
        component: () => import('./pages/SystemSettings/AutomationSettings.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Settings', label: 'Automation Settings' }],
        }
    },
    {
        path: '/email-templates',
        name: 'emailTemplates',
        component: () => import('./pages/SystemSettings/EmailTemplates.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Settings', label: 'Email Templates' }],
        }
    },
    {
        path: '/my-drive',
        name: 'MyDrive',
        component: () => import('./pages/MyDrive/MyDrive.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Files', label: 'My Drive' }],
        }
    },
    {
        path: '/file-categories',
        name: 'File Categories',
        component: () => import('./pages/MyDrive/FileCategories.vue'),
        meta: {
            breadcrumb: [{ parent: 'My Drive', label: 'File Categories' }],
        }
    },
    {
        path: '/pricing-rules',
        name: 'Pricing Rules',
        component: () => import('./pages/SystemSettings/PricingRules.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Settings', label: 'Pricing Rules' }],
        }
    },

    {
        path: '/payment-gateways',
        name: 'Payment Gateways',
        component: () => import('./pages/SystemSettings/PaymentGateways.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Settings', label: 'Payment Gateways' }],
        }
    },

    {
        path: '/orders/order-categories',
        name: 'Order Categories',
        component: () => import('./pages/Orders/OrderCategories.vue'),
        meta: {
            breadcrumb: [{ parent: 'Manage Orders', label: 'Order Categories' }],
        }
    },

];





const router = createRouter({
    history: createWebHashHistory(),
    routes,
    // scrollBehavior() {
    //     return { left: 0, top: 0 };
    // }
    scrollBehavior(to, from, savedPosition) {
        // Exists when Browser's back/forward pressed
        if (savedPosition) {
            return savedPosition
            // For anchors
        } else if (to.hash) {
            return { selector: to.hash }
            // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
        } else if (from.path === to.path) {
            return {}
        }

        // Scroll to top
        return { x: 0, y: 0 }
    }
});


export default router;