import axios from 'axios';

export const $axios = axios.create({
    // baseURL: `http://server.com/travel-app/minibus/w360-backend/api/`,
    // baseURL: `https://travel360.rsfcrm.com/backend/api/`,
    // baseURL: `https://transport.technopert.com/backend/api/`,
    baseURL: `https://tms.runbusinesssmartly.com/backend/api/`,
    // baseURL: `https://movers.runbusinesssmartly.com/backend/api/`,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

// export const $axios = $ax.interceptors.response.use(function (response) {
//         console.log(response);
//         // Any status code within the range of 2xx cause this function to trigger
//         // Do something with response data
//         return response;
//     }, function (error) {
//         console.log(error);
//         alert("errr");
//         // Any status codes outside the range of 2xx cause this function to trigger
//         // Do something with response error
//         return Promise.reject(error);
// });